import classes from './Wallet.module.css'

const Wallet = ({ data }) => {
    return  <article>
        
            <div className={classes.tableContainer}>
            <table className={`table ${classes.table}`}>
                <thead>
                <tr>
                    <th>Cryptocurrency</th>
                    <th>Amount</th>
             
                </tr>
                </thead>
                <tbody>
                {data.map((row) => (
                    <tr key={row.id}>
                    <td>{row.name}</td>
                    <td>{row.amount.toFixed(3)}</td>
                   
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        
       
    </article>
}

export default Wallet;