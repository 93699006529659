import React from "react";

const PostFeed = (props) => {
  const posts = props.posts;

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <h2 className="mt-5 mb-3">Post Feed</h2>
          
            <div className="card mb-4" key='1'>
              <div className="card-body">
                <h4 className="card-title">Oi</h4>
                <p className="card-text">Splash me balls</p>
                <a href="#" className="btn btn-primary">
                  Read More &rarr;
                </a>
              </div>
              <div className="card-footer text-muted">
                Posted on  by 
              </div>
            </div>
      
        </div>
        <div className="col-lg-8 mx-auto">
          <h2 className="mt-5 mb-3">Post Feed</h2>
          
            <div className="card mb-4" key='1'>
              <div className="card-body">
                <h4 className="card-title">Oi</h4>
                <p className="card-text">Splash me balls</p>
                <a href="#" className="btn btn-primary">
                  Read More &rarr;
                </a>
              </div>
              <div className="card-footer text-muted">
                Posted on  by 
              </div>
            </div>
      
        </div>
        <div className="col-lg-8 mx-auto">
          <h2 className="mt-5 mb-3">Post Feed</h2>
          
            <div className="card mb-4" key='1'>
              <div className="card-body">
                <h4 className="card-title">Oi</h4>
                <p className="card-text">Splash me balls</p>
                <a href="#" className="btn btn-primary">
                  Read More &rarr;
                </a>
              </div>
              <div className="card-footer text-muted">
                Posted on  by 
              </div>
            </div>
      
        </div>
        <div className="col-lg-8 mx-auto">
          <h2 className="mt-5 mb-3">Post Feed</h2>
          
            <div className="card mb-4" key='2'>
              <div className="card-body">
                <h4 className="card-title">Oi</h4>
                <p className="card-text">Splash me balls</p>
                <a href="#" className="btn btn-primary">
                  Read More &rarr;
                </a>
              </div>
              <div className="card-footer text-muted">
                Posted on  by 
              </div>
            </div>
      
        </div>
        <div className="col-lg-8 mx-auto">
          <h2 className="mt-5 mb-3">Post Feed</h2>
          
            <div className="card mb-4" key='3'>
              <div className="card-body">
                <h4 className="card-title">Oi</h4>
                <p className="card-text">Splash me balls</p>
                <a href="#" className="btn btn-primary">
                  Read More &rarr;
                </a>
              </div>
              <div className="card-footer text-muted">
                Posted on  by 
              </div>
            </div>
      
        </div>
      </div>
    </div>
  );
};

export default PostFeed;
