import React, { useState } from "react";
import classes from './AvaxNfts.module.css';
import joePegsIcon from '../../Images/joePegsIcon.png';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import NftCard from '../pages/flex';

const AvalancheNfts = (props) => {
    const avaxNfts = useSelector(state => state.blockchain.balances.avaxNfts);

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(15);

    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = avaxNfts.slice(indexOfFirstItem, indexOfLastItem);

    const avaxNftList = (
        <NftCard 
            nfts={currentItems}
            type='avaxType'
            imageUrl={`https://cryptobroskis.com/cryptobroskis/joepegsImg/`}
            link1={`https://joepegs.com/item/avalanche/0xeb4f5d458b135a8993239f91ab3f45ab92cd519e/`}
            icon1={joePegsIcon}
        />
    );
    

    const pageCount = Math.ceil(avaxNfts.length / itemsPerPage);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    return (
        <article className={classes.nftDisp}>
            {avaxNftList}
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={classes.pagination}
                activeClassName={classes.active}
                previousLabel="< previous"
               renderOnZeroPageCount={null}
               pageClassName="page-item"
               pageLinkClassName="page-link"
               previousClassName="page-item"
               previousLinkClassName="page-link"
               nextClassName="page-item"
               nextLinkClassName="page-link"
               breakClassName="page-item"
               breakLinkClassName="page-link"
               containerClassName="pagination"
               activeClassName="active"
            />
        </article>
    );
};

export default AvalancheNfts;
