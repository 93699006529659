import React from "react";
import classes from './Nav.module.css';
import whale from '../../Images/Whale.png'


const Nav = (props) => {
    
    let imageUrl;
    if (props.account != "00" && props.picId != null) {
      imageUrl = `https://cryptobroskis.com/cryptobroskis/joepegsImg/${props.picId}.png`;
    } else {
      imageUrl = `https://cryptobroskis.com/cryptobroskis/joepegsImg/1.png`;
    }
  
    return (
        <nav>
            <div className={classes.flexContainer}>
                <div>
                    <img src={imageUrl} className={classes.nft}></img>
                </div>
            </div>
            
            <h3 className={classes.accountHigh}>Account Info</h3>
            <ul className="list-group">
                <li className={`list-group-item d-flex justify-content-between align-items-center ${classes.linkText}`}>
 
                   Wallet: 0x...{props.account}
                   
                </li>
              
                {/* <li className="list-group-item d-flex justify-content-between align-items-center" className={classes.linkText}>
                      Dao Member    <span className="badge bg-secondary rounded-pill">Yes</span>
                
                </li> */}
            </ul>
    

            {props.balance > 200 && <img src={whale} className={classes.whale} />}
        </nav>
        
        
    )

};

export default Nav;