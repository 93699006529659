import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import PostFeed from './components/pages/PostFeed';
import Home from './components/pages/Home';
import App from './App';
import MyNfts from './components/pages/MyNfts';
import AvaxNfts from './components/pages/AvaxNfts';
import EthNfts from './components/pages/EthNfts';
import Wallet from './components/pages/Wallet';
import Friends from './components/pages/Friends';
import AvaxCol from './components/UI/NftCard';
import Flex from './components/pages/flex'



function Server() {
  const blockchain = useSelector((state) => state.blockchain);

  const data = [
    {
      id: 1,
      name: 'Ethereum',
      amount: blockchain.balances.balanceEth * .000000000000000001,
    },
    {
      id: 2,
      name: 'Avalanche',
      amount: blockchain.balances.balanceAvax * .000000000000000001,
    },
    // Add other tokens as needed...
  ];
  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/posts', element: <PostFeed /> },
        { path: '/nfts', element: <MyNfts ethNfts={blockchain.balances.avaxNfts}/> },
        { path: '/avaxnfts', element: <AvaxNfts avaxNfts={blockchain.balances.avaxNfts}/> }, // Corrected path
        { path: '/wallet', element: <Wallet data={data} /> },
        { path: '/ethnfts', element: <EthNfts /> },
        { path: '/friends', element: <Friends /> },
        { path: '/flex', element: <Flex
            nftId={blockchain.balances.avaxNfts}
            EthNft={blockchain.balances.ethNfts} 
          /> 
        },
      ],
    },
  ]);

  return <RouterProvider router={router}> {/* RouterProvider wraps the component tree */}
    {/* Render any components that use routing here */}
  </RouterProvider>;
}

export default Server;
