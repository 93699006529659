import React, { useState } from "react";
import classes from './EthNfts.module.css';
import Looks from '../../Images/looksrareIcon.png';
import OpenSea from '../../Images/OpenSeaIcon.png';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import NftCard from '../pages/flex';

const EthNfts = (props) => {
    const ethNfts = useSelector(state => state.blockchain.balances.ethNfts);

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(15);

    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ethNfts.slice(indexOfFirstItem, indexOfLastItem);

    const ethNftList = (
            <NftCard 
                nfts={currentItems}
                imageUrl={`https://cryptobroskis.com/cryptobroskis/ethImg/`}
                type='ethType'
                link1={`https://opensea.io/assets/ethereum/0xeaa7aa689eec0e3ceb70e288d23e01e874a98e5c/`}
                icon1={OpenSea}
                link2={`https://looksrare.org/collections/0xeAa7Aa689eec0e3Ceb70E288d23e01E874a98E5c/`}
                icon2={Looks}
            />
    );




    const pageCount = Math.ceil(ethNfts.length / itemsPerPage);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    return (
        <article className={classes.nftDisp}>
            {ethNftList}
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={classes.pagination}
                activeClassName={classes.active}
                
               renderOnZeroPageCount={null}
               pageClassName="page-item"
               pageLinkClassName="page-link"
               previousClassName="page-item"
               previousLinkClassName="page-link"
               nextClassName="page-item"
               nextLinkClassName="page-link"
               breakClassName="page-item"
               breakLinkClassName="page-link"
               containerClassName="pagination"
               activeClassName="active"
            />
        </article>
    );
};

export default EthNfts;
