import React from "react";
import classes from './flex.module.css';

const NftCard = ({ nfts, imageUrl, type, link1, icon1, link2, icon2 }) => {
  return (
    <div className={classes.grid}>
      {nfts.map(nft => (
        <div className={classes.box} key={nft.toString()}>
          <div className={classes.card}>
            <div className={classes.cardImageContainer}>
              <a href={`${link1}${nft}`} target="_blank" rel="noreferrer noopener">
                <img
                  src={`${imageUrl}${nft}.png`}
                  alt={`CryptoBroski Nft #${nft}`}
                  className={`card-img-top ${classes.nftImg}`}
                />
              </a>
              <div className={classes.imageOverlay}>
                <img
                  src={`${imageUrl}${nft}.png`}
                  alt={`CryptoBroski Nft #${nft}`}
                  className={classes.overlayImg}
                />
              </div>
            </div>
            <div className={classes.cardHeader}>Nft {nft}</div>
            <div className={classes.cardBody}>
              <a href={`${link1}${nft}`} target="_blank" rel="noreferrer noopener">
                <img src={icon1} alt="Icon 1" className={classes.img} />
              </a>
              #{nft}
              {link2 && (
                <a href={`${link2}${nft}`} target="_blank" rel="noreferrer noopener">
                  <img src={icon2} alt="Icon 2" className={classes.img} />
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NftCard;
