const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  errorMsg: "",
  balancesLoading: false,
  balances: { balanceEth: null, balanceAvax: null, balanceRFuji: null, tokenBalanceEth: null, tokenBalanceAvax: null, avaxNfts: [1,2,3,4,5,6,7,8,9,10,15,69,178,397,420,444,666,796,908,971,1111,1189,1203,2222,2292,2494,2495,2591,2634,2655 ], ethNfts:[1,69,420]},
  balancesError: null
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    case "FETCH_BALANCES_REQUEST":
      return { 
        ...state, 
        balancesLoading: true, 
        balancesError: null 
      };
    case "FETCH_BALANCES_SUCCESS":
      return { 
        ...state, 
        balancesLoading: false, 
        balances: action.payload 
      };
    case "FETCH_BALANCES_FAILURE":
      return { 
        ...state, 
        balancesLoading: false, 
        balancesError: action.payload 
      };
    default:
      return state;
  }
};

export default blockchainReducer;
