// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";



const web3Eth = new Web3(`https://mainnet.infura.io/v3/6a0905e3a85848a9a0f4363b7cfb5678`);
const web3Avax = new Web3(`https://avalanche-mainnet.infura.io/v3/6a0905e3a85848a9a0f4363b7cfb5678`);
const web3RFuji = new Web3(`https://avalanche-fuji.infura.io/v3/6a0905e3a85848a9a0f4363b7cfb5678`);
// and so on for other networks...

import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};


// New action types
const fetchBalancesRequest = () => ({ type: "FETCH_BALANCES_REQUEST" });
const fetchBalancesSuccess = (payload) => ({ type: "FETCH_BALANCES_SUCCESS", payload });
const fetchBalancesFailure = (payload) => ({ type: "FETCH_BALANCES_FAILURE", payload });


const cb_addAvax = '0xEB4F5d458B135A8993239f91ab3f45Ab92CD519e'
const cb_addEth = '0xeaa7aa689eec0e3ceb70e288d23e01e874a98e5c'
const cb_abi = 
[
  {
     "inputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"constructor"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"approved",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"Approval",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"operator",
           "type":"address"
        },
        {
           "indexed":false,
           "internalType":"bool",
           "name":"approved",
           "type":"bool"
        }
     ],
     "name":"ApprovalForAll",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"previousOwner",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"newOwner",
           "type":"address"
        }
     ],
     "name":"OwnershipTransferred",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"Transfer",
     "type":"event"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"approve",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"owner",
           "type":"address"
        }
     ],
     "name":"balanceOf",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"cost",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"getApproved",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"hiddenMetadataUri",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"operator",
           "type":"address"
        }
     ],
     "name":"isApprovedForAll",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"maxMintAmountPerTx",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"maxSupply",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_mintAmount",
           "type":"uint256"
        }
     ],
     "name":"mint",
     "outputs":[
        
     ],
     "stateMutability":"payable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_mintAmount",
           "type":"uint256"
        },
        {
           "internalType":"address",
           "name":"_receiver",
           "type":"address"
        }
     ],
     "name":"mintForAddress",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"name",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"owner",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"ownerOf",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"paused",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"renounceOwnership",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"revealed",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"safeTransferFrom",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        },
        {
           "internalType":"bytes",
           "name":"_data",
           "type":"bytes"
        }
     ],
     "name":"safeTransferFrom",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"operator",
           "type":"address"
        },
        {
           "internalType":"bool",
           "name":"approved",
           "type":"bool"
        }
     ],
     "name":"setApprovalForAll",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_cost",
           "type":"uint256"
        }
     ],
     "name":"setCost",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"string",
           "name":"_hiddenMetadataUri",
           "type":"string"
        }
     ],
     "name":"setHiddenMetadataUri",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_maxMintAmountPerTx",
           "type":"uint256"
        }
     ],
     "name":"setMaxMintAmountPerTx",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"bool",
           "name":"_state",
           "type":"bool"
        }
     ],
     "name":"setPaused",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"bool",
           "name":"_state",
           "type":"bool"
        }
     ],
     "name":"setRevealed",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"string",
           "name":"_uriPrefix",
           "type":"string"
        }
     ],
     "name":"setUriPrefix",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"string",
           "name":"_uriSuffix",
           "type":"string"
        }
     ],
     "name":"setUriSuffix",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"bytes4",
           "name":"interfaceId",
           "type":"bytes4"
        }
     ],
     "name":"supportsInterface",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"symbol",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_tokenId",
           "type":"uint256"
        }
     ],
     "name":"tokenURI",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"totalSupply",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"transferFrom",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"newOwner",
           "type":"address"
        }
     ],
     "name":"transferOwnership",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"uriPrefix",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"uriSuffix",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_owner",
           "type":"address"
        }
     ],
     "name":"walletOfOwner",
     "outputs":[
        {
           "internalType":"uint256[]",
           "name":"",
           "type":"uint256[]"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"withdraw",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  }
];
// Create contract instances
const cb_contractEth = new web3Eth.eth.Contract(cb_abi, cb_addEth);
const cb_contractAvax = new web3Avax.eth.Contract(cb_abi, cb_addAvax);
// New action to fetch balances
export const fetchBalances = (account) => {
  return async (dispatch) => {
    dispatch(fetchBalancesRequest());
    try {
      const balanceEth = await web3Eth.eth.getBalance(account);
      const balanceAvax = await web3Avax.eth.getBalance(account);
      const balanceRFuji = await web3RFuji.eth.getBalance(account);
      // Call balanceOf function on each contract
      const tokenBalanceEth = await cb_contractEth.methods.balanceOf(account).call();
      const ethNfts = await cb_contractEth.methods.walletOfOwner(account).call();
      const tokenBalanceAvax = await cb_contractAvax.methods.balanceOf(account).call();
      const avaxNfts = await cb_contractAvax.methods.walletOfOwner(account).call();
      dispatch(fetchBalancesSuccess({ balanceEth, balanceAvax, avaxNfts, ethNfts, balanceRFuji, tokenBalanceEth, tokenBalanceAvax }));
    } catch (error) {
      dispatch(fetchBalancesFailure(error.message));
    }
  };
};


