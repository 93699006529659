import React, { useState } from "react";
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux'
import Looks from '../../Images/looksrareIcon.png';
import OpenSea from '../../Images/OpenSeaIcon.png';
import JoePegs from '../../Images/joePegsIcon.png';
import classes from './Aside.module.css'


const Aside = (props) => {   
 
    return (
        <aside> 
            <div>
                <ul className="nav flex-column dropdown">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    {/* <li className="nav-item">
                        <Link to="/posts" className="nav-link ">Post Feed</Link>
                    </li> */}
                    </li>
                    <li data-bs-toggle="dropdown">
                        <Link className="nav-link">My Nft's</Link>   
                    </li>
                        <ul className="dropdown-menu">
                            <li className="nav-item dropdown">
                                <Link to="/avaxnfts" className="nav-link ">Avax Broskis <span className="badge bg-primary rounded-pill ">{props.avaxCount}</span></Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="/ethnfts" className="nav-link ">ETH Broskis  <span className="badge bg-primary rounded-pill">{props.ethCount}</span></Link>
                            </li>
                        </ul>
                    {/* <li className="nav-item">
                        <Link to="/friends" className="nav-link">Friends</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/games" className="nav-link">Games</Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link to="/wallet" className="nav-link">Wallet</Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link to="/dao" className="nav-link disabled">Dao</Link>
                    </li> */}
                    <li className="nav-item">
                        <a href="https://opensea.io/collection/officialcryptobroskis" className="nav-link" target="_blank" rel="noreferrer noopener"><img src={OpenSea} alt="Opensea Icon" className={classes.img}></img> BrokenSea</a>
                    </li>
                    <li className="nav-item">
                        <a href="https://looksrare.org/collections/0xeAa7Aa689eec0e3Ceb70E288d23e01E874a98E5c" className="nav-link" target="_blank" rel="noreferrer noopener"><img src={Looks} alt="Opensea Icon" className={classes.img}></img> LooksRare</a>
                    </li>
                    <li className="nav-item">
                        <a href="https://joepegs.com/collections/avalanche/crypto-broskis" className="nav-link" target="_blank" rel="noreferrer noopener"><img src={JoePegs} alt="JoePegs Icon" className={classes.img}></img> JoePegs</a>
                    </li>               
                </ul>
            </div>
            
            
        </aside>
    )

};

export default Aside;