import {useSelector} from 'react-redux'
import classes from './Home.module.css'

function Home() {
    return  <article>
         <div className="px-4 py-5 my-5 text-center">
            
            <img src="logo.png" alt="Bootstrap"  height="250"></img>
            <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
            Welcome to CryptoBroski's NFT project website! Each piece is algorhthmicly unique with some having a personal touch for extra rarity, and I'm excited to share them with you. Whether you're a collector, enthusiast, or simply curious about the world of NFTs, I invite you to explore my collection and experience the power and potential of this revolutionary technology.
            </p>
           
            </div>
        </div>
       
    </article>
}

export default Home;