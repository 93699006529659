import React, { useState } from "react";
import classes from './Friends.module.css';
import joePegsIcon from '../../Images/joePegsIcon.png';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

const MyFriends = (props) => {
    const friends = useSelector(state => state.friends);

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = friends.slice(indexOfFirstItem, indexOfLastItem);

    const friendList = currentItems.map((friend) => {
        return (
            <div className="card " className={`${classes.flexContainer}`} key={friend.toString()}>
                <div className={classes.flexItem}>
                    
                    <div className="card-body">
                        <p className="card-title" className={classes.avaxType}> Friend</p>
                       
                        
                        <p>{friend}</p>
                    </div>
                </div>
            </div>
        );
    });

    const pageCount = Math.ceil(friends.length / itemsPerPage);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    return (
        <article className={classes.nftDisp}>
            {friendList}
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={classes.pagination}
                activeClassName={classes.active}
                previousLabel="< previous"
               renderOnZeroPageCount={null}
               pageClassName="page-item"
               pageLinkClassName="page-link"
               previousClassName="page-item"
               previousLinkClassName="page-link"
               nextClassName="page-item"
               nextLinkClassName="page-link"
               breakClassName="page-item"
               breakLinkClassName="page-link"
               containerClassName="pagination"
               activeClassName="active"
            />
        </article>
    );
};

export default MyFriends;
