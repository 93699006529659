import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Outlet} from 'react-router-dom';
import { connect, fetchBalances } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Footer from "./components/Main/Footer";
import Aside from "./components/Main/Aside";
import Nav from "./components/Main/Nav"



const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const user = useSelector((state) => state.user);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 7777,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    if (blockchain.account) {
      dispatch(fetchBalances(blockchain.account));
    }
  }, [blockchain.account, dispatch]);

  const connectToMeta = (e) => {
    e.preventDefault();
    dispatch(connect());
    getData();
  }
  const testBen = () => {
  console.log(blockchain.totalBalanceAvax, "Test")
  };
  let nftTotal = blockchain.balances.avaxNfts.length + blockchain.balances.ethNfts.length;
  const state = useSelector((state) => state);
  let trimmedAccount = "00";
  if (blockchain.account != null){
    trimmedAccount = blockchain.account.substring(38, 42)
  } 
  return (
    <div id="body">
        {blockchain.account == null && (
          <button onClick={connectToMeta}>Connect to Web3 Wallet</button>
        )}
      
      
        <div id="main">
          
            <Nav 
              account={trimmedAccount}
              balance={nftTotal}
              picId={blockchain.balances.avaxNfts[0]}
              
            />
            <Outlet
             
            />
            
            <Aside
              avaxCount={blockchain.balances.avaxNfts.length}
              ethCount={blockchain.balances.ethNfts.length}
            />
        </div>
        <Footer/>
    </div>
  );
}

export default App;
